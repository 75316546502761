
import * as React from 'react';
import Link from '@mui/material/Link';
// import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';
import { createTheme } from '@mui/material/styles';

import "yup-phone";

import axios from 'axios';
import Grid from '@mui/material/Grid';

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
// import Card from 'react-bootstrap/Card';
import IconButton from '@mui/material/IconButton';

// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';

import TextField from '@mui/material/TextField';

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import { Formik } from 'formik';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';

import Badge from '@mui/material/Badge';
// import Slider from "react-slick";
import YouTube from 'react-youtube';
import Alert from '@mui/material/Alert';
//Bootstarp components
// import { Button } from "react-bootstrap";
// import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
// import Helmet from 'react-helmet';
/*import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
*/

import './App.css';
import './index.css';
import './Logo.css';
import './Category.css';
import './Cards.css';
import './Content.css';
import './Cart.css';
import './PopUp.css';
import './ViewCart.css';
import './LoadMore.css';
import './Footer.css';
// import ConstantTheme from './ColorUtils';
// import { set } from 'lodash';



const theme = createTheme({
 
});

export default function Checkout() {


  //const [storeId,setStoreId] = React.useState(null);
  const baseImgUrl = 'https://pinkgrocery-thumbnail.s3.amazonaws.com';
  const [store,setStore] = React.useState(null);
  const [isLoading, setIsLoading ] = React.useState(false);
  const baseUrl = "https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta";
  const [products,setProducts] = React.useState([]);
  const [totalAmount,setTotalAmount] = React.useState(0);
  const [totalQuantity,setTotalQuantity] = React.useState(0);
  const [currency,setCurrency] = React.useState('INR');
  const [cart,setCart] = React.useState([]);
  const [open,setOpen] =  React.useState(false);
  const [orderStatus,setOrderStatus] = React.useState('');
  const [deliveryCharge,setDeliveryCharge] = React.useState(0);
  const [deliveryNotes, setDeliveryNotes] = React.useState(null);
  const [deliveryTerms, setDeliveryTerms] = React.useState(null);
  const [currencyCode,setCurrencyCode] = React.useState("₹");
  const [categories,setCategories] = React.useState(null);
  const [popup, setPopup] = React.useState({ popupShow: false });
  const [order, setOrder] = React.useState({ popupShow: false,type:"success" });
  const [cartShow, setcartShow] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState('');
  const [totalPages,setTotalPages] =  React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [recordsPerPage, setRecordsPerPage] = React.useState(10);
  const [loadingMore,setLoadingMore] = React.useState(false);
  const [currentImage,setCurrentImage] = React.useState(null);
  const [currentType,setCurrentType] = React.useState('image');
  const [count,setCount] = React.useState(0);
  const [metaData,setMataData] = React.useState([]);
  const [isHovering, setIsHovering] = React.useState('none');
  const [constantColor, setConstantColor] = React.useState({
    "web": {
      "a_bk": "#F4F2F2",
      "m_bk": "#fff",
      "m_bt_a": "#F26901",
      "bt_ac": "#F4F2F2",
      "ft_bk": "#242424",
      "ft_tx": "#fff",
      "bst_bt": "#242424",
      "m_tx": "#000",
      "m_tx_a": "#fff",
      "bst_tx": "#fff",
      "sm_bk": "#fff",
      "sm_clr": "#000",
      "sm_hv_bk": "#F26901",
      "sm_hv_clr": "#fff"
    }
  })


  //razorpay scripts
  function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
  
  //read querystring 
  function getQueryVariable(variable)
  {
        var query = window.location.search.substring(1);
        //console.log(query)//"app=article&act=news_content&aid=160990"
        var vars = query.split("&");
        //console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
        for (var i=0;i<vars.length;i++) {
                    var pair = vars[i].split("=");
                    //console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
        if(pair[0] == variable){return pair[1];}
         }
         return(false);
  }

  //page load
  React.useEffect(()=>{
    const fetchData = async () => {
      setIsLoading(true);
      let store=null;

     

      console.log("id:::::", window.location.pathname);
      //console.log(getQueryVariable('storeId'));
      let storeId = getQueryVariable('storeId');
      let seoTag = getQueryVariable('seoTag');

      if(window.location.pathname.replaceAll('/','')!=="") seoTag= window.location.pathname.replaceAll('/','');
      // console.log("seotag ::::::::",getQueryVariable('seoTag'));
      let params  = {professionalId:storeId};
      let seoparams  = {seoId:seoTag};

      if (storeId !== false) 
      {
        params = {professionalId:storeId}
      }
      else if(!window.location.hostname.includes("localhost"))
      {
        params = {domain: window.location.hostname}
      }
      
      
     

      //store details
      await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/store',params)
      .then(async (response)=>{
        // console.log("store ::::::",response);
        if(response.data[0].theme !==null){
          setStore(response.data[0]);
        }
        else{
          response.data[0].theme = constantColor;
          setStore(response.data[0]);
        }
        store = response.data[0];
        document.title = store.businessName +' Checkout';
        console.log("store ::::::",response.data);

        if (seoTag !== false) 
        {
          seoparams  = {seoId:seoTag}
          // await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/product?page=1&perPage='+recordsPerPage+'&sortBy=createdOn&sortKey=desc',{storeId:store.professionalId,cdngteunitsInStock:"1","additionalMetadata.seoTag":seoTag},{})
          await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/product?page=1&perPage='+recordsPerPage+'&sortBy=createdOn&sortKey=desc',{storeId:store.professionalId,"additionalMetadata.seoTag":seoTag},{})
          .then((response)=>{
            if(response.data.length>0)
              {
               let productData =response.data[0]
               productData.quantity = 0;
                const myArray = [];

                let x = {
                  url:  productData.thumbnailPath,
                  type: 'image'
                }            
                myArray.push(x);

                productData.photos !==null && productData.photos!== undefined && productData.photos.map(img => {
                  let image = {
                    url: img,
                    type: 'image'
                  }
                  myArray.push(image);
                })

                productData.videos !==null && productData.videos !== undefined && productData.videos.map(video => {
                  let videoPlay = {
                    url: video,
                    type: 'video'
                  }
                  myArray.push(videoPlay);
                })

              productData.myArray=myArray;
              setCurrentImage(myArray[0]);
                // console.log("Popup response :::::::",productData);
              
            setPopup({
              popupShow: true,
              product: productData
            }) 
            } 
          })
          .catch((error)=>{
            console.log(error);
          })
  
      
        }
          
      })
      .catch((error)=>{
        console.log(error);
      })

      //categories details
      await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/category?sortBy=createdOn&sortKey=asc',{professionalId:store.professionalId})
      .then((response)=>{
        // console.log("categories ::::::",response);
        setCategories(response.data);
       })
      .catch((error)=>{
        console.log(error);
      })

      //delivery charges 
      await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/delivery',{storeId:store.professionalId})
      .then((response)=>{
        //console.log(response);
        if(response.data.length>0)
        {
          setDeliveryNotes(response.data[0].notes);
          setDeliveryCharge(response.data[0].baseFare);
          //deliveryCharges = response.data[0].baseFare;
          setDeliveryTerms(response.data[0].terms);
        }
          
      })
      .catch((error)=>{
        console.log(error);
      })

   

      if(store!==null)
      { 
    
        setCurrency(store.businessCurrency);
        if(store.businessCurrency.toLowerCase() ==="inr")
        {
          setCurrencyCode("₹");
        }
        //setTotalAmount(0);
        
        // await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/product?page=1&perPage='+recordsPerPage+'&sortBy=createdOn&sortKey=desc',{storeId:store.professionalId,cdngteunitsInStock:"1"},{})
        await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/product?page=1&perPage='+recordsPerPage+'&sortBy=createdOn&sortKey=desc',{storeId:store.professionalId},{})
        .then((response)=>{
          setTotalPages(response.headers["x-total-page"]);
          setCurrentPage(response.headers["x-page"]);
          // console.log("Product response::::::",response);
          let items = [];
          response.data.map((p)=>{
            p.quantity = 0;
            
            items.push(p);
          })
          setProducts(items);
            
        })
        .catch((error)=>{
          console.log(error);
        })
      }
      setIsLoading(false);
    };
    fetchData();
    
  }, []);

  //add to basket   
  const addBasket = async(product) =>{
    //console.log("add::::",product.productId, product.productName,product.quantity);
    let newCart =  cart;

    let newProducts = products;

    //console.log("product:::::", newProducts);

    let productIndex = newProducts.findIndex(el => el.productId == product.productId);
    newProducts[productIndex] = {...newProducts[productIndex], quantity: parseInt(product.quantity) + 1};



    let cartIndex = newCart.findIndex(el => el.productId == product.productId);
    //console.log("cartIndex:::::",cartIndex);
    if(cartIndex < 0)
    {
      product.quantity = 1;
      product.totalPrice = product.price;
      newCart.push(product);
    }
    else
    {
      newCart[cartIndex] = {...newCart[cartIndex], quantity: parseInt(newCart[cartIndex].quantity) + 1};
      newCart[cartIndex] = {...newCart[cartIndex], totalPrice: (parseInt(newCart[cartIndex].quantity)) *  parseFloat(product.price)};
    }

    
    
    //setTotalAmount(parseFloat(totalAmount)+(parseInt(product.quantity) +1) *  product.price);
    let sumTotal=0;
    let sumQuantity=0;
    newCart.map((p)=>{
      if(p.quantity>0)
      {
        sumTotal = parseFloat(sumTotal) + parseInt(p.quantity) * parseFloat(p.price);
        sumQuantity = parseInt(sumQuantity) + parseInt(p.quantity);

        //console.log("p::::",p,sumTotal);
      }
    })


    //popup product update 
    if(popup.popupShow && popup.product!==undefined && popup.product!==null && product.productId === popup.product.productId )
    {
      //if(popup.product.quantity>1)popup.product.quantity = parseInt(popup.product.quantity) + 1;
      
      if(productIndex < 0)
      {
        popup.product.quantity = parseInt(popup.product.quantity) + 1;
        setPopup({
          popupShow: true,
          product:popup.product
        })
      }
      else
      {
        setPopup({
          popupShow: true,
          product:newProducts[productIndex]
        })
      }
    }

   // console.log("sumtotal:::",sumTotal);
    setTotalAmount(sumTotal);
    setProducts(newProducts);
    setTotalQuantity(sumQuantity);
    setCart(newCart);

  }

const addItem = async(product) =>{
//console.log("add::::",product.productId, product.productName,product.quantity);
  let newProducts =  cart;

  let index = newProducts.findIndex(el => el.productId == product.productId);
  newProducts[index] = {...newProducts[index], quantity: parseInt(product.quantity) + 1};
  newProducts[index] = {...newProducts[index], totalPrice: (parseInt(product.quantity) +1) *  parseFloat(product.price)};
  
  //setTotalAmount(parseFloat(totalAmount)+(parseInt(product.quantity) +1) *  product.price);
  let sumTotal=0;
  let sumQuantity=0;
  newProducts.map((p)=>{
    if(p.quantity>0)
    {
      sumTotal = parseFloat(sumTotal) + parseInt(p.quantity) * parseFloat(p.price);
      sumQuantity = parseInt(sumQuantity) + parseInt(p.quantity);
      //console.log("p::::",p,sumTotal);
    }
  })
 // console.log("sumtotal:::",sumTotal);
  setTotalAmount(sumTotal);
  setCart(newProducts);
  setTotalQuantity(sumQuantity);

  //products
  let tempProducts =  products;
  let tempindex = tempProducts.findIndex(el => el.productId == product.productId);
  tempProducts[tempindex] = {...tempProducts[tempindex], quantity: parseInt(product.quantity) + 1};
  setProducts(tempProducts);
  
  

}

const removeItem = async(product) =>{
  //console.log("remove::::",product);
  let newProducts =  cart;
  if(product.quantity>1)
  {
    let index = newProducts.findIndex(el => el.productId == product.productId);
    newProducts[index] = {...newProducts[index], quantity: parseInt(product.quantity) - 1};
    newProducts[index] = {...newProducts[index], totalPrice: (parseInt(product.quantity) -1) *  parseFloat(product.price)};
   
    //setTotalAmount(parseFloat(totalAmount)-(parseInt(product.quantity) -1) *  product.price);
    let sumTotal=0;
    let sumQuantity=0;
    newProducts.map((p)=>{
      sumTotal = parseFloat(sumTotal) + (parseInt(p.quantity) * parseFloat(p.price));
      sumQuantity = parseInt(sumQuantity) + parseInt(p.quantity);
    })
    //console.log("sumtotal::::",sumTotal);
    setTotalAmount(sumTotal);
    setCart(newProducts);
    setTotalQuantity(sumQuantity);

    //products
    let tempProducts =  products;
    let tempindex = tempProducts.findIndex(el => el.productId == product.productId);
    tempProducts[tempindex] = {...tempProducts[tempindex], quantity: parseInt(product.quantity) - 1};
    setProducts(tempProducts);

  }
  else
  {
    let index = newProducts.findIndex(el => el.productId == product.productId);
    //newProducts[index] = {...newProducts[index], quantity: parseInt(product.quantity) - 1};
    //newProducts[index] = {...newProducts[index], totalPrice: (parseInt(product.quantity) -1) *  product.price};
    newProducts.splice(index,1);
    //setTotalAmount(parseFloat(totalAmount)-(parseInt(product.quantity) -1) *  product.price);
    let sumTotal=0;
    let sumQuantity=0;
    newProducts.map((p)=>{
      sumTotal = parseFloat(sumTotal) + (parseInt(p.quantity) * parseFloat(p.price));
      sumQuantity = parseInt(sumQuantity) + parseInt(p.quantity);
    })
    //console.log("sumtotal::::",sumTotal);
    setTotalAmount(sumTotal);
    setCart(newProducts);
    setTotalQuantity(sumQuantity);

    //products
    let tempProducts =  products;
    let tempindex = tempProducts.findIndex(el => el.productId == product.productId);
    tempProducts[tempindex] = {...tempProducts[tempindex], quantity: 0};
    setProducts(tempProducts);
  }

}

const handleClose = async () =>
{
  setOpen(false);
  setTotalAmount(0);
  setCart([]);

}

const closeOrderPopup = async () =>
{
  setOrder({popupShow:false});
  setTotalAmount(0);
  setCart([]);

}

function closePopup() {
  // console.log('jjj')
  setPopup({popupShow:false})
  setCurrentImage(null)
  setCount(0)
}

//const handleClose = () => {
//  console.log('jjj')
 // setcartShow(false);
  //setcartShow(false); setcartShow(false);
  //console.log(cartShow)
//}
const handleShow = () => {
  setcartShow(true);
}

const clearBasket = () => {

  console.log("clear basket::::");
  setCart([]);
  setTotalAmount(0);
  setTotalQuantity(0);

  let items = [];
  products.map((p)=>{
    p.quantity = 0;
    items.push(p);
  })
  setProducts(items);

}

const loadMore = async() => {

  setLoadingMore(true);
  // let data = {storeId:store.professionalId,cdngteunitsInStock:"1"};
  let data = {storeId:store.professionalId};
  if(selectedCategory!=='') data.servicesId=selectedCategory;
  await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/product?page='+(parseInt(currentPage)+1)+'&perPage='+recordsPerPage+'&sortBy=createdOn&sortKey=desc',data,{})
    .then((response)=>{
          
          //console.log(response);
          setTotalPages(response.headers["x-total-page"]);
          setCurrentPage(response.headers["x-page"]);
          let items = [];
          response.data.map((p)=>{
           
            let index =cart.findIndex(el => el.productId == p.productId)
            if(index!=-1)
            {
              p.quantity = cart[index].quantity;
            }
            else
            {
              p.quantity = 0;
            }
            items.push(p);
          })
          setProducts(products.concat(items));
          setLoadingMore(false);
            
        })
        .catch((error)=>{
          console.log(error);
          setLoadingMore(false);
        })
}

const search = async(value) => {

  console.log("search::::",value);
  if(value.length>3)
  {
    setIsLoading(true);
    // let data = {storeId:store.professionalId,cdngteunitsInStock:"1",searchproductNamelCase:value};
    let data = {storeId:store.professionalId,searchproductNamelCase:value};
    if(selectedCategory!=='') data.servicesId=selectedCategory;
    await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/product?page=1&perPage='+recordsPerPage+'&sortBy=createdOn&sortKey=desc',data,{})
      .then((response)=>{
            
            //console.log(response);
            setTotalPages(response.headers["x-total-page"]);
            setCurrentPage(response.headers["x-page"]);
            let items = [];
            response.data.map((p)=>{
            
              let index =cart.findIndex(el => el.productId == p.productId)
              if(index!=-1)
              {
                p.quantity = cart[index].quantity;
              }
              else
              {
                p.quantity = 0;
              }
              items.push(p);
            })
            setProducts(items);
            setIsLoading(false);
              
          })
          .catch((error)=>{
            console.log(error);
            setIsLoading(false);
          })
  }
  else
  {
    categorySelect('');
  }
}




async function categorySelect(data) {
  //props.card(data);
  setIsLoading(true);
   console.log("category:::::",data);
  //  let postData = {storeId:store.professionalId,cdngteunitsInStock:"1"}
   let postData = {storeId:store.professionalId}
   if(data!=='')
   {
    postData.servicesId = data.servicesId;
    setSelectedCategory(data.servicesId);
   }
   else
   {
    setSelectedCategory(data);
   }
   await axios.post('https://go2mrilulf.execute-api.us-east-1.amazonaws.com/beta/product?page=1&perPage='+recordsPerPage+'&sortBy=createdOn&sortKey=desc',postData,{})
    .then((response)=>{

          //console.log(response);
          setTotalPages(response.headers["x-total-page"]);
          setCurrentPage(response.headers["x-page"]);
          let items = [];
          response.data.map((p)=>{
           
            let index =cart.findIndex(el => el.productId == p.productId)
            if(index!=-1)
            {
              p.quantity = cart[index].quantity;
            }
            else
            {
              p.quantity = 0;
            }
            items.push(p);
          })
          setProducts(items);
          setIsLoading(false);
        })
        .catch((error)=>{
          console.log(error);
          setIsLoading(false);
        })
}

  const productDetails = (product) => {
    // console.log("array:::::",product);
    const myArray = [];
    let x = {
      url: product.thumbnailPath,
      type: 'image'
    }

    myArray.push(x);

    product.photos !== null && product.photos !== undefined && product.photos.map(img => {
      let image = {
        url: img,
        type: 'image'
      }
      myArray.push(image);
    })

    product.videos !== null && product.videos !== undefined && product.videos.map(video => {
      let videoPlay = {
        url: video,
        type: 'video'
      }
      myArray.push(videoPlay);
    })
    product.myArray=myArray;
    setCurrentImage(myArray[0]);
   // console.log("images :::::::", product);
    setPopup({
      popupShow: true,
      product: product
    })  
  }


  function onclickPrev(){
    let subTemp = count -1;
    if(subTemp >=0){
      setCurrentImage(popup.product.myArray[subTemp]);
      setCount(subTemp);
    // console.log("Prev::::::",subTemp);
    }
  }

  function onclickNext(){
     let addTemp = count +1;
     if(addTemp < popup.product.myArray.length){
       setCurrentImage(popup.product.myArray[addTemp]);
       setCount(addTemp);
    //  console.log("Next::::::",addTemp);
    //  console.log("Total array::::::",popup.product.myArray);
  }
  }

React.useEffect(()=>{
  const fetchData = async () => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    let sumTotal=0;
    if(cart!==null)
    {
      let newProducts = cart;
      newProducts.map((p)=>{
        sumTotal = parseFloat(sumTotal) + (parseInt(p.quantity) * parseFloat(p.price));
      })
      setTotalAmount(sumTotal);
    }
  };
  fetchData();
  
}, []);

//form validation
const LoginSchema = Yup.object().shape({
  name: Yup.string().required('Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  phone: Yup.string().required("Provide valid phone").matches(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/, "Invalid phone number"),
  // phone:Yup.string().phone("IN", true).required("Provide valid phone"),
  email: Yup.string().email('Invalid email').required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  state: Yup.string().required('Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  // zip: Yup.number('Provide valid zip').min(110001,"Provide valid zip").max(999999,"Provide valid zip").required('Required'),
  zip: Yup.string().required('Required').matches("^[1-9]{1}[0-9]{2}[0-9]{3}$", "Zip / Postal Code must be in number" ),
  country: Yup.string().required('Required').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
// console.log("selectedCategory::::",selectedCategory);

const badgeStyle = {
  "& .MuiBadge-badge": {
      color: '#fff',
      backgroundColor: "#F26901",
      width: 19,
      height: 19,
      borderRadius: '50%',
      zIndex: 0
  }
}
const opts = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 0,
  },
};

// Mouse hover
const handleMouseEnter = (smType) => {
  setIsHovering(smType);
};

const handleMouseLeave = () => {
  setIsHovering('none');
};



  return (
      <>

      {store!==null && 
       <>
        {/* <Helmet
          title={store.businessName}
          meta={[{ name: 'description', content: store.seoDescription }]}></Helmet> */}
           {/**logo code */}
           <nav className="navbar fixed-top navbar-light bg-light">
              <div className='container-fluid'>
              <div className="logo-img ">
                  <img alt="Store Logo" width="100%" src={baseImgUrl + "/" + store.professionalId + "/" + store.thumbnailPath } />
              </div>
              <div className='d-flex'>
              <div className="searchbtn">
              <Tooltip title="Search product" arrow>
                  <div className="form-group has-search">
                      <span className="fa fa-search form-control-feedback"></span>
                      <input type="text" className="form-control" onChange={(event)=>search(event.target.value)} placeholder="Search" />
                  </div>
                  </Tooltip>
                  {/* <div className="filter m-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                          <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5v-2zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2h-11z" />
                      </svg>
                  </div> */}
              </div>
                  <div className='secticon' onClick={() => handleShow()}>
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" className="bi bi-bag-check" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                          <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                      </svg> */}
                      <Tooltip title="Open basket" arrow>
                      <button className="rounded-circle style" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-cart3" viewBox="0 0 18 18">
                              <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                          </svg>
                          <div style={{backgroundColor: store.theme.web.m_bt_a}} className="badge rounded-circle d-flex justify-content-center align-items-center">{totalQuantity}</div>
                    </button>
                    </Tooltip>                    
                  </div>
              </div>
              </div>
           </nav>
           {/**categories */}
           <div className='container-fluid midcontant-top' style={{backgroundColor: store.theme.web.a_bk}}>
           <div className='button mt-4 px-3'>
              <span onClick={() => categorySelect('')}>
                <button type="button" className="btn category-item mb-2" style={{backgroundColor: selectedCategory===''? store.theme.web.m_bt_a : store.theme.web.m_tx_a, 'color': selectedCategory===''? store.theme.web.m_tx_a: store.theme.web.m_tx }}>            
                {"All Products"}
                </button>
              </span> 
            {categories!=null && categories.map((item,index) => (

              <span key={"catgry"+index} onClick={() => categorySelect(item)}>
                <button type="button" className="btn category-item mb-2" style={{backgroundColor: item.servicesId===selectedCategory? store.theme.web.m_bt_a : store.theme.web.m_tx_a, 'color': item.servicesId===selectedCategory? store.theme.web.m_tx_a: store.theme.web.m_tx }}>
                  <img alt="Store Logo" src={baseImgUrl + "/" + store.professionalId + "/"+item.thumbnailPath} className="image" />
                  {" "+item.service}
                </button>
              </span>   

            ))}             
           </div>

           {/**products */}
          <div className="avilable-item">
            <div className="row">
              {isLoading && <div style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginTop: '25px', width: '100%', height: '400px' }}><CircularProgress /></div>}
              {products.length === 0 && !isLoading && <div style={{ textAlign: 'center', alignItems: 'center', marginTop: '25px', justifyContent: 'center', width: '100%' }}><img style={{ 'width': '150px', 'height': '150px' }} src={baseImgUrl+"/others/coming-soon.png"} alt="no records" /></div>}
              {!isLoading && products.map((item,index) => (
                <div key={"prdct"+index} className="col-lg-2 col-md-3 col-sm-4 col-6 mt-4">
                  <div className="productcard">
                    { parseInt(item.unitsInStock) !== 0 &&
                    <div>
                    {item.additionalMetadata !== null && item.additionalMetadata!==undefined && JSON.stringify(item.additionalMetadata) !== "{}" && item.additionalMetadata.uniqueTag !== null && 
                       <span className='choice' style={{backgroundColor: store.theme.web.bst_bt}}>
                      <span className='choice_btn' style={{color: store.theme.web.bst_tx}}>{item.additionalMetadata.uniqueTag}</span>
                      </span>
                     } 
                     </div>
                    }
                      { parseInt(item.unitsInStock) === 0 &&
                       <span className='outofstack' style={{backgroundColor: 'red'}}>
                      <span className='outofstack_btn' style={{color: store.theme.web.bst_tx}}>{'Out of stock'}</span>
                      </span>
                    }
                    <div className="py-4 px-3">
                    <div className="pic img-fluid mb-3">

                      {/* <img src={item.thumbnailPath} className="img-fluid" alt='' /> */}
                      <img src={baseImgUrl + "/" + item.storeId + "/" + item.thumbnailPath} style={{ width: '100%', height: '100%', borderRadius: 8,marginTop:4 }} alt="" onClick={() => productDetails(item)} />
                    </div>

                    <h6 className="product_title">{item.productName.length> 50 ? `${item.productName.substring(0, 50)}...` : item.productName}</h6>
                    
                    <span className='product-unitweight'>{item.unitWeight}</span>
                    <div className="d-flex justify-content-between align-items-center">
                    <div className="popular_price-section">
                      <span className="popular_discount">
                        <span className="rupee_symbol">₹</span>{item.price}</span>
                    </div>
                    {parseInt(item.unitsInStock) !== 0 &&
                    <div>
                    <Tooltip title="Add to cart" arrow>
                        <IconButton sx={{ color: store.theme.web.m_bt_a }} aria-label="add" onClick={() => addBasket(item)}>
                          <Badge badgeContent={item.quantity} sx={badgeStyle} >
                            <AddShoppingCartIcon sx={{ height: 22, width: 22 }} />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                      </div>
                    }

                      </div>
                      {parseFloat(item.price) < parseFloat(item.mRetailPrice) && <>
                        <span className="popular_mrp mr-3">{item.mRetailPrice}</span> 
                        <span className="popular_percent">{item.discount}% off</span>
                      </>
                      }
                    {/*<button type='button' className='btn btn-primary btn-bottom' onClick={()=>addBasket(item)}>+Add to Cart</button>*/}
                  </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
           {/**load more */}
           {currentPage<totalPages && !isLoading && 
           <div className="loadmore">
            <button type='button' style={{backgroundColor: store.theme.web.m_bt_a, color: store.theme.web.m_tx_a}} className='btn btn-bottom' onClick={()=>loadMore()}>Load More
              {loadingMore &&

              <Spinner animation="border" role="status" size="sm" style={{marginLeft:'3px'}}>
              </Spinner>
              }
            </button>
           </div>
            }
            {/**footer */}
            <div className="footer" style={{backgroundColor: store.theme.web.ft_bk,color: store.theme.web.ft_tx}}>
              <div className="row">
                <div className="col-sm-12 col-md-7">
                  <div className="footer_store">
                    {/*<img alt="Store Logo" width="30%" src={baseImgUrl + "/" + store.professionalId + "/" + store.thumbnailPath } />*/}
                    <div className="logo-img mb-2">
                       <img alt="Store Logo" width="100%" src={baseImgUrl + "/" + store.professionalId + "/" + store.thumbnailPath } />
                    </div>
                    <h5>{store.businessName}</h5>
                    <div>{store.address1+', '+store.city}</div>
                    <div>{store.businessState+', '+store.businessPinCode}</div>                   
                  </div>
                </div>
                <div className="col-sm-12 col-md-2 mb-3">
                    <div><a href={store.termsUrl} style={{textDecoration:'none',textDecorationColor:'#fff', color:'#fff'}}  target="_blank">Terms & Conditions</a></div>
                    <div><a href={store.privacyUrl} style={{textDecoration:'none',textDecorationColor:'#fff', color:'#fff'}}  target="_blank">Privacy Policy</a></div>
                    <div className="mt-3"><a href="https://razorpay.com/" target="_blank"> <img referrerPolicy="origin" src = "https://badges.razorpay.com/badge-light.png " style = {{height: 45, width: 113}} alt = "Razorpay | Payment Gateway | Neobank" /></a></div>
                </div>
                <div className="col-sm-12 col-md-3">
                <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16" style={{ marginRight: 10 }}>
                  <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>{store.alternatePhone}</div>
                <div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16" style={{ marginRight: 10 }}>
                  <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                </svg>{store.businessEmail}</div>
                <div>
                  {store.storeUrl !== null && store.storeUrl !== undefined && store.storeUrl !== '' &&
                    <a href={store.storeUrl} style={{ textDecoration: 'none', textDecorationColor: '#fff', color: '#fff' }} target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-browser-chrome" viewBox="0 0 16 16" style={{ marginRight: 10 }}>
                  <path fillRule="evenodd" d="M16 8a8.001 8.001 0 0 1-7.022 7.94l1.902-7.098a2.995 2.995 0 0 0 .05-1.492A2.977 2.977 0 0 0 10.237 6h5.511A8 8 0 0 1 16 8ZM0 8a8 8 0 0 0 7.927 8l1.426-5.321a2.978 2.978 0 0 1-.723.255 2.979 2.979 0 0 1-1.743-.147 2.986 2.986 0 0 1-1.043-.7L.633 4.876A7.975 7.975 0 0 0 0 8Zm5.004-.167L1.108 3.936A8.003 8.003 0 0 1 15.418 5H8.066a2.979 2.979 0 0 0-1.252.243 2.987 2.987 0 0 0-1.81 2.59ZM8 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" />
                </svg>{store.storeUrl.replace('https://', '')}</a>
                 }</div>
                <div className="footer-social">
                {store.facebookUrl !== null && store.facebookUrl !== undefined && store.facebookUrl !== '' &&
                  <a href={store.facebookUrl} target="_blank" style={{backgroundColor:isHovering === 'fb'? store.theme.web.sm_hv_bk : store.theme.web.sm_bk, color: isHovering === 'fb' ? store.theme.web.sm_hv_clr: store.theme.web.sm_clr}} onMouseEnter={()=>handleMouseEnter('fb')} onMouseLeave={handleMouseLeave}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg></a>
                }
                 {store.facebookUrl !== null && store.facebookUrl !== undefined && store.facebookUrl !== '' &&
                  <a href={store.instagramUrl} target="_blank" style={{backgroundColor:isHovering === 'insta' ? store.theme.web.sm_hv_bk : store.theme.web.sm_bk, color: isHovering === 'insta' ? store.theme.web.sm_hv_clr: store.theme.web.sm_clr}} onMouseEnter={()=>handleMouseEnter('insta')} onMouseLeave={handleMouseLeave}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg></a>
                  }
                </div>
                </div>
              </div>
            </div>
             {/**footer company*/}
             <div className="footer_developer">
              <div className="row">
                <div className="col">
                  <div className="powered_title">
                    <a href="https://www.buildurstore.com/" target="_blank">
                      <img alt="Buildurstore Logo" src={baseImgUrl + "/others/icon.ico"} style={{width:30, height: 30, marginRight:10}} />
                       {'Powered by Buildurstore'} 
                    </a> 
                  </div>
                </div>
               
              </div>
            </div>
           {/**popup product details */}
           {popup.popupShow &&
           <div className="popup">
            <div className="product_popupinner">
                {/*<button className='rounded-circle justify-content-end' onClick={() => closePopup()}> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                </svg></button>*/}
                  <div className='text-end'>
                  <Tooltip title="Close popup" arrow>
                <button type="button" className="btn-close text-reset" onClick={() => closePopup()}></button>
                  </Tooltip>
                  </div>
                  <div className="row">
                    <div className='col-sm-6'>
                   {parseInt(popup.product.unitsInStock) !== 0 &&
                     <div>  
                      {popup.product.additionalMetadata !== null && popup.product.additionalMetadata!==undefined && JSON.stringify(popup.product.additionalMetadata) !== "{}" && popup.product.additionalMetadata.uniqueTag !== null && 
                       <span className='choice_pop'>
                      <span className='choice_pop_btn'>{popup.product.additionalMetadata.uniqueTag}</span>
                      </span>
                     } 
                     </div>
                    }
                    {parseInt(popup.product.unitsInStock) === 0 &&
                      <span className='outofstack' style={{ backgroundColor: 'red' }}>
                        <span className='outofstack_btn' style={{ color: store.theme.web.bst_tx }}>{'Out of stock'}</span>
                      </span>
                    }
                     <div className="pic choice-pt text-center img-fluid">
                        {/* <img src={baseImgUrl + "/" + popup.product.storeId + "/" + popup.product.thumbnailPath} style={{ width: '90%' }} alt="" /> */}
                        { currentImage !==null && currentImage !== undefined && currentImage !== '' && currentImage.type === 'video' &&
                            <YouTube 
                                videoId={currentImage.url.split("=")[1]}
                                opts={opts}
                             />
                         }
                         { currentImage !== null && currentImage !==undefined && currentImage !== '' && currentImage.type === 'image' &&
                            <img src={baseImgUrl + "/" + popup.product.storeId + "/" + currentImage.url} style={{ width: '55%', borderRadius: 8,marginTop:4}} alt="" />
                         }
                         {/* <span>{'Name:'+currentImage.url}</span> */}
                      </div>
                      {/* {JSON.stringify(popup.product.myArray)} */}
                      {popup.product.myArray.length >1 ? 
                    <>
                    <div  className="d-flex justify-content-around align-items-center mt-4 mb-1">
                        <svg onClick={() => onclickPrev()} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-left-circle-fill" viewBox="0 0 16 16">
                          <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                        </svg>
                         {/* <span>{count}</span> */}
                        <svg onClick={() => onclickNext()} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                          <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                        </svg>
                      </div>
                    </>  
                    : null
                    
                    }
                      
                    </div>
                    <div className='col-sm-6'>
                    
                      <h4 className="margin">{popup.product.productName}</h4>
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="popular_price-section">
                          <span className="popular_discount">
                            <span className="rupee_symbol">₹</span>{popup.product.price}</span>
                          {parseFloat(popup.product.price) < parseFloat(popup.product.mRetailPrice) && <>
                            <span className="popular_mrp ml-3">{popup.product.mRetailPrice}</span>
                            <span className="popular_percent">{popup.product.discount}% off</span>
                          </>}
                        </div>

                      {parseInt(popup.product.unitsInStock) !== 0 &&
                       <div>
                       <Tooltip title="Add to cart" arrow>
                         <IconButton sx={{ color: store.theme.web.m_bt_a }} aria-label="add" onClick={() => addBasket(popup.product)}>
                           <Badge badgeContent={popup.product.quantity} sx={badgeStyle}>
                             <AddShoppingCartIcon sx={{ height: 25, width: 25 }} />
                           </Badge>
                         </IconButton>
                       </Tooltip>
                       </div>
                    }
                      </div>
                      <div className='popup_description'>{popup.product.productInfo.replaceAll("</br>",'\n').replaceAll("<br/>",'\n\n')}</div>
                    </div>
                  </div>
            </div>
          </div>
          }
           {/**popup order status */}
           {/**order.popupShow &&
           <div className="popup">
            <div className="orderStatus_popup">
              
                 <div className='text-sm-end'>
                 <Tooltip title="Close popup" arrow>
                    <button type="button" className="btn-close text-reset" onClick={() => closeOrderPopup()}></button>
                </Tooltip>
                </div>
                  <div className="row">
                    <div className='col'>
                      <div className='ppb-2'> {orderStatus}</div>
                    </div>
                  </div>
            </div>
          </div>
         */ }
        </div>
        {/**cart  */}

             {/* <div className='headercontent d-flex'>
                  <h3>Basket</h3> 
              <div className='secticon ms-auto'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-bag-check" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0z" />
                        <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z" />
                    </svg>
                </div>
            </div> */}
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{'overflow':'scroll'}}>
                <div className="offcanvas-header">
                    <h4 id="offcanvasRightLabel">Basket</h4>
                    <Tooltip title="Delete basket" arrow>
                    <Link onClick={clearBasket}>
                    <span style={{textDecoration:"none",color:store.theme.web.m_bt_a, cursor:'pointer'}}>Clear basket</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16" style={{color:store.theme.web.m_bt_a, cursor:'pointer'}}>
                        <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
                      </svg>
                    </Link>
                    </Tooltip>
                    <Tooltip title="Close basket" arrow>
                       <button type="button" id="cart-model" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </Tooltip>
                </div>
                <div className="offcanvas-body orderitems" >
                    
                 {cart.length === 0 && <div style={{textAlign : 'center'}}><img src={baseImgUrl+"/others/empty-cart.png"} style={{width:'100px',height:'100px', marginBottom:10 }} alt="empty cart" /> <h6>Your basket is empty</h6></div>}   
                 {cart.length > 0 && cart.map((item, index) => (
                    <div key={"crt"+index} className='orders d-flex'>

                        <div className='orderpic d-flex'>
                            <img src={baseImgUrl+"/"+item.storeId+"/"+item.thumbnailPath} style={{ width: '40%' }} alt="" />
                            <div className='ordername'>
                                <h6>{item.productName.length> 50 ? `${item.productName.substring(0, 50)}...` : item.productName}</h6>
                                {/*<h6 className='fontcolor'>{'('+item.quantity+' X '+item.price.toFixed(2)+')  '+currencyCode + item.totalPrice.toFixed(2)}</h6>*/}
                                
                            </div>

                        </div>
                        <div className='quantity'>
                          <h6 className='fontcolor mb-4'>{currencyCode + item.totalPrice.toFixed(2)}</h6>
                            <h6 className='fontcolor'>
                                <button style={{color:'#fff',backgroundColor: store.theme.web.m_bt_a,borderColor: store.theme.web.m_bt_a}} className='buttonshop' onClick={() => removeItem(item)}>-</button >
                                <span className='px-1'> {item.quantity} </span>
                                <button style={{color:'#fff',backgroundColor: store.theme.web.m_bt_a,borderColor: store.theme.web.m_bt_a}} className='buttonshop' onClick={() => addItem(item)}>+</button>
                            </h6>
                        </div>
                    </div>
                    ))}
                    {cart.length!==null && cart.length>0 &&
                    <div className='totalgrid' style={{backgroundColor: store.theme.web.bt_ac}}>
                        <div className='row'>
                            <div className='subtotal d-flex'>
                                <span>Subtotal</span>
                                <h6 className='ms-auto'> {currencyCode + totalAmount.toFixed(2)}</h6>
                            </div>
                            <div className='totalsale d-flex'>
                                <span>Delivery Charge</span>
                                <h6 className='ms-auto'> {currencyCode + deliveryCharge.toFixed(2)}</h6>
                            </div>
                            {/*<div className='totalsale d-flex'>
                                <span>Total Sales Tax</span>
                                <h6 className='ms-auto'>₹0</h6>
                            </div>*/}
                            <div className='total d-flex'>
                                <h4>Total</h4>
                                <h4 className='ms-auto'>{currencyCode + (parseFloat(totalAmount) + parseFloat(deliveryCharge)).toFixed(2)}</h4>

                            </div>

                        </div>
                    </div>
                    }

                      {order.popupShow &&
                        <Alert onClose={() => setOrder({ popupShow: false })} severity={order.type} sx={{ marginTop: '20px' }}>{orderStatus}</Alert>
                      }
                   
                  {cart.length!==null && cart.length>0 &&
                 
                    <Formik
                    initialValues={{ name: '',phone:'',email:'',address:'',city:'',state:'',zip:'',country:'' }}
                    validationSchema={LoginSchema}
                    onSubmit={async (values) => {
                      //alert(JSON.stringify(values));
                      
                      let deliveryAddress= {
                        name:values.name,
                        phone:values.phone,
                        email:values.email,
                        address: values.address,
                        pinCode: values.zip,
                        city: values.city,                      
                        state:values.state,
                        country:values.country,
                        
                      };
                      let selectedProducts= [];
                        if(cart!==null && cart.length>0)
                        {
                          cart.map((p)=>{
                            let product = { productId: p.productId, qty: p.quantity};
                            selectedProducts.push(product);
                          })
                        }

                      // const Razorpay = Razorpay();
                      //console.log("sttore:::::",store);
                        let post_data = {
                          paymentMethod: "razorpay",
                          name:values.name,
                          phone:values.phone,
                          email:values.email,
                          storeId:store.professionalId,
                          userId:store.userId,
                          orderType:"customerweb",
                          products: selectedProducts,
                          deliveryAddress: deliveryAddress
                        }
                        const endpoint_url =baseUrl + "/razorpay";

                        await axios.post(endpoint_url,post_data,{}).then(response => {
                          //console.log("rsssss::::",response.data);
                          const order = response.data;
                          
                          if(order.orderId != undefined && order.orderId !=null)
                          {

                          
                              //orderId = response.data.orderId;
                              var options = {
                                description: order.businessName,
                                image: 'https://pinkgrocery-thumbnail.s3.amazonaws.com/'+store.professionalId+'/'+order.thumbnailPath,
                                currency: order.businessCurrency,
                                key: order.keyId, // Your api key
                                amount: order.amount,
                                order_id:order.orderId,
                                name: order.businessName,
                                handler: async function (response) {
                                  //alert(response.razorpay_payment_id);
                                  //alert(response.razorpay_order_id);
                                  //alert(response.razorpay_signature);
                                  post_data.razorpay_payment_id = response.razorpay_payment_id;
                                  post_data.razorpay_order_id =  response.razorpay_order_id;
                                  post_data.razorpay_signature = response.razorpay_signature;
                                  var order_url = baseUrl + "/order";
                                  await axios.post(order_url,post_data).then(resp => {
                                    //document.getElementById('cart-model').click();
                                    //alert('Success');
                                    setOrderStatus("Your order has been received successfully. You will receive notification for the progress.");
                                    //setOpen(true);
                                    //setcartShow(false);
                                    setOrder({popupShow:true,type:"success"});
                                    setTotalQuantity(0);
                                    let tempItems = [];
                                    products.map((p)=>{
                                      p.quantity = 0;
                                      tempItems.push(p);
                                    })
                                    setProducts(tempItems);
                                    setCart([]);
                                   
                                    //console.log(resp.data);
                                  }).catch((error) => {
                                    setOrderStatus("Your order is not processed at the moment. You will be refunded if any amount is paid.");
                                    //setOpen(true);
                                    //setcartShow(false);
                                    setOrder({popupShow:true,type:"error"});
                                    
                                    // handle failure
                                    //alert(`Error: ${error.code} | ${error.description}`);
                                    //alert('Error: Payment failed');
                                  });

                                },
                                prefill: {
                                  email: post_data.email,
                                  contact: post_data.phone,
                                  name: post_data.name
                                },
                                theme: {color: 'green'}
                              };

                              const paymentObject = new window.Razorpay(options);
                              paymentObject.open();
                              
                              
                            }
                            else if(order.error != undefined && order.error !=null)
                            {
                              setOrderStatus(order.error);
                                    //setOpen(true);
                                    //setTotalQuantity(0);
                                   // setcartShow(false);
                                    setOrder({popupShow:true,type:"error"});
                            }
                          }).catch(error => {
                              
                              console.log("order:::",error.response);
                          });
                    }}
                    >
                    {({ handleChange, handleBlur, handleSubmit, values ,errors,
                      isValid}) => (
                        <>
                        <div className='shipping' style={{backgroundColor: store.theme.web.bt_ac}}>
                        <h4>Shipping Details</h4>
                        <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                          <TextField
                            required
                            id="name"
                            name="name"
                            label="Name"
                            fullWidth
                            error={errors.name}
                            autoComplete="given-name"
                            variant="standard"
                            onBlur={handleBlur('name')}
                            value={values.name}
                            onChange={handleChange('name')}
                          />
                          {errors.name ? <div className="form_error">{errors.name}</div> : null}

                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="phone"
                            name="phone"
                            label="Phone"
                            error={errors.phone}
                            fullWidth
                            autoComplete="family-name"
                            variant="standard"
                            onBlur={handleBlur('phone')}
                            value={values.phone}
                            onChange={handleChange('phone')}
                          />
                          {errors.phone ? <div className="form_error">{errors.phone}</div> : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="email"
                            name="email"
                            error={errors.email}
                            label="Email"
                            fullWidth
                            autoComplete="family-name"
                            variant="standard"
                            onBlur={handleBlur('email')}
                            value={values.email}
                            onChange={handleChange('email')}
                          />
                          {errors.email ? <div className="form_error">{errors.email}</div> : null}
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            required
                            id="address"
                            name="address"
                            label="Address"
                            fullWidth
                            error={errors.address}
                            autoComplete="shipping address-line1"
                            variant="standard"
                            onBlur={handleBlur('address')}
                            value={values.address}
                            onChange={handleChange('address')}
                          />
                           {errors.address ? <div className="form_error">{errors.address}</div> : null}
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="city"
                            name="city"
                            label="City"
                            fullWidth
                            error={errors.city}
                            autoComplete="shipping address-level2"
                            variant="standard"
                            onBlur={handleBlur('city')}
                            value={values.city}
                            onChange={handleChange('city')}
                          />
                          {errors.city ? <div className="form_error">{errors.city}</div> : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="state"
                            name="state"
                            error={errors.state}
                            label="State"
                            fullWidth
                            variant="standard"
                            onBlur={handleBlur('state')}
                            value={values.state}
                            onChange={handleChange('state')}
                          />
                          {errors.state ? <div className="form_error">{errors.state}</div> : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="zip"
                            name="zip"
                            label="Zip / Postal code"
                            fullWidth
                            error={errors.zip}
                            autoComplete="shipping postal-code"
                            variant="standard"
                            onBlur={handleBlur('zip')}
                            value={values.zip}
                            onChange={handleChange('zip')}
                          />
                          {errors.zip ? <div className="form_error">{errors.zip}</div> : null}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            required
                            id="country"
                            error={errors.country}
                            name="country"
                            label="Country"
                            fullWidth
                            autoComplete="shipping country"
                            variant="standard"
                            onBlur={handleBlur('country')}
                            value={values.country}
                            onChange={handleChange('country')}
                          />
                          {errors.country ? <div className="form_error">{errors.country}</div> : null}
                        </Grid>
                        </Grid>
                        </div>

                        {totalAmount>0 &&
                        <button
                          type='button'
                          className="buttonstyle"
                          style={{backgroundColor: store.theme.web.m_bt_a,borderColor: store.theme.web.m_bt_a}}
                          onClick={handleSubmit}
                          >
                          Continue to Payment
                        </button>
                        }                        
                        </>                     
                    )}                   
                    </Formik>               
                  }
                </div>
            </div>

        {/*<Copyright store={store} />*/}
        
        </>
      }
    </>
  );
}
