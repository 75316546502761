import * as React from 'react';
import ConstantTheme from './ColorUtils';
import './App.css';
import Checkout from './Checkout';

function App() {
  return (
    <div>
      <Checkout/>
    </div>
  );
}

export default App;
